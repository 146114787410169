<template>
  <div class="w-full !h-full">
  <DeletePixelModalV2
    :PixelObj="PixelObjTodeleteObject.object"
  ></DeletePixelModalV2>
  <table class="pixel_table text-left w-full">
    <thead
      class="text-[#757A8A] font-semibold text-[0.875rem] font-poppins bg-[#F4F6FA]"
    >
      <tr>
        <td class="py-3 pl-3 w-1/5">Name</td>
        <td class="py-3 text-left w-1/5 pl-10">Network</td>
        <td class="py-3 text-center w-1/5">Pixel ID</td>
        <td class="py-3 text-center w-1/5">Created</td>
        <td class="py-3 pr-3 text-right w-1/5">Actions</td>
      </tr>
    </thead>
    <tbody v-if="getPixelLoaders.get">
      <tr>
        <td colspan="5">
          <div class="p-5 text-center">
            <clip-loader :size="'26px'" :color="'#1d4ed8'"></clip-loader>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody v-else-if="!getPixelLoaders.get && getFilteredPixelList.length == 0">
      <tr>
        <td colspan="5" class="">
          <p class="no-rows text-[#3C4549] text-[0.875rem] font-poppins">
            <i class="fab fa-bandcamp" aria-hidden="true"></i>
            No Pixel created yet, please create your Pixel first.
          </p>
        </td>

      </tr>
    </tbody>
    <tbody
      v-else
      class="font-poppins text-[#3C4549] text-[0.875rem]"
    >
      <tr
      v-for="(pixel,index) in getFilteredPixelList"
        class="border-b border-[#ECEEF5] px-3"
      >
         <td class="py-4 pl-3 w-1/5" v-tooltip="pixel.name && pixel.name.length >= 20 ? pixel.name : ''">
          {{   pixel.name }}
        </td>
        <td class="py-3 text-left w-1/5 pl-10 truncate">
          <div class="social_icons_square align_center">
              <span class="icon mr-2" :class="getPixelSocialClass(pixel.type.label,1)">
                <i :class="getPixelSocialClass(pixel.type.label,2)"></i>
              </span>

              <span class="text ml-1">{{pixel.type.label}}</span>
              <span>{{getGooglePixelOnKey(pixel.type.label, pixel.key, googlePixelsTypeKey)}}</span>
            </div>
        </td>
        <td class="py-4 text-center w-1/5" v-tooltip="pixel.pixel && pixel.pixel.length >= 20 ? pixel.pixel : ''">
          {{ pixel.pixel  }}
        </td>
        <td class="py-4 text-center w-1/5" >
          {{ pixel.created_at | relativeCaptilize }}
        </td>

        <td class="py-4 pr-3 text-right  w-full flex gap-1 justify-end items-center h-full">
          <svg
                  :class="
                    ActiveModal === 'editPixel'
                      ? 'rounded-full !bg-[#F4F6FA)]'
                      : ''
                  "
                  class="cursor-pointer mr-2"
                  v-tooltip="'Edit Domain'"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  @click.prevent="
                    ActiveModal = 'editPixel';
                    editPixel(pixel);
                  "
                >
                  <path
                    d="M11.6533 1.15998C12.0759 0.737402 12.6491 0.5 13.2467 0.5C13.8443 0.5 14.4174 0.737402 14.84 1.15998C15.2626 1.58256 15.5 2.1557 15.5 2.75332C15.5 3.35093 15.2626 3.92407 14.84 4.34665L4.74889 14.4378L0.5 15.5L1.56222 11.2511L11.6533 1.15998Z"
                    stroke="#3C4549"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <svg
                  :class="
                    ActiveModal === 'deletePixel'
                      ? 'rounded-full !bg-[#F4F6FA)]'
                      : ''
                  "
                  class="cursor-pointer mr-2"
                  v-tooltip="'Delete Custom Domain'"
                  width="16"
                  height="18"
                  viewBox="0 0 16 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  @click.prevent="
                    ActiveModal = 'deletePixel';
                    deletePixelOpenModal(({pixelId: pixel._id,indexId: index}));
                  "
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.70143 1.91636C5.88803 1.72976 6.14112 1.62493 6.40501 1.62493H9.59505C9.85894 1.62493 10.112 1.72976 10.2986 1.91636C10.4852 2.10296 10.5901 2.35605 10.5901 2.61994V3.61398H5.41V2.61994C5.41 2.35605 5.51483 2.10296 5.70143 1.91636ZM4.77554 4.81398C4.78694 4.81463 4.79843 4.81496 4.81 4.81496C4.82157 4.81496 4.83306 4.81463 4.84446 4.81398H11.1556C11.167 4.81463 11.1785 4.81496 11.1901 4.81496C11.2016 4.81496 11.2131 4.81463 11.2245 4.81398H12.9826V15.3801C12.9826 15.644 12.8778 15.897 12.6912 16.0836C12.5045 16.2703 12.2515 16.3751 11.9876 16.3751H4.01249C3.7486 16.3751 3.49551 16.2703 3.30891 16.0836C3.12231 15.897 3.01748 15.644 3.01748 15.3801V4.81398H4.77554ZM4.21 3.61398V2.61994C4.21 2.03779 4.44126 1.47948 4.8529 1.06783C5.26455 0.656187 5.82286 0.424927 6.40501 0.424927H9.59505C10.1772 0.424927 10.7355 0.656187 11.1472 1.06783C11.5588 1.47948 11.7901 2.03779 11.7901 2.61994V3.61398H15.1776C15.5089 3.61398 15.7776 3.88261 15.7776 4.21398C15.7776 4.54536 15.5089 4.81398 15.1776 4.81398H14.1826V15.3801C14.1826 15.9622 13.9513 16.5205 13.5397 16.9322C13.128 17.3438 12.5697 17.5751 11.9876 17.5751H4.01249C3.43034 17.5751 2.87203 17.3438 2.46038 16.9322C2.04874 16.5205 1.81748 15.9622 1.81748 15.3801V4.81398H0.822412C0.491041 4.81398 0.222412 4.54536 0.222412 4.21398C0.222412 3.88261 0.491041 3.61398 0.822412 3.61398H2.41743H4.21ZM6.40487 7.60191C6.73624 7.60191 7.00487 7.87054 7.00487 8.20191V12.987C7.00487 13.3183 6.73624 13.587 6.40487 13.587C6.0735 13.587 5.80487 13.3183 5.80487 12.987V8.20191C5.80487 7.87054 6.0735 7.60191 6.40487 7.60191ZM10.195 8.20191C10.195 7.87054 9.92637 7.60191 9.595 7.60191C9.26363 7.60191 8.995 7.87054 8.995 8.20191V12.987C8.995 13.3183 9.26363 13.587 9.595 13.587C9.92637 13.587 10.195 13.3183 10.195 12.987V8.20191Z"
                    fill="#97304A"
                  />
                </svg>
        </td>
      </tr>
    </tbody>

  </table>
</div>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import {pixelTypes} from '@/state/modules/mutation-types'
import {DARK_PURPLE_LOADER_COLOR} from '@/common/constants'
import {googlePixelsTypeKey} from '@/common/attributes'
import DeletePixelModalV2 from './dialogs/DeletePixelModalV2.vue'

export default ({
  components:{
    Button: () => import("@/ui/ui-kit/v2/Button.vue"),
    DeletePixelModalV2,
  },
  computed: {
    ...mapGetters(['getFilteredPixelList', 'getPixelLoaders']),
  },
  props: [
    'payload'
  ],
  data: function () {
    return {
      color: DARK_PURPLE_LOADER_COLOR,
      googlePixelsTypeKey: googlePixelsTypeKey,
      PixelObjTodeleteObject : {
        object:null,
      },
      ActiveModal:'',
    }
  },
  methods: {
    ...mapMutations([pixelTypes.SET_PIXEL]),
    ...mapActions(['deletePixel', 'fetchPixels']),
    deletePixelOpenModal(PixelObjTodDelete){
      this.$set(this.PixelObjTodeleteObject, 'object',PixelObjTodDelete);
      setTimeout(() => {
        this.$bvModal.show("delete-pixel-modal");
      }, 150);

    },
    editPixel (pixel) {
      this[pixelTypes.SET_PIXEL](JSON.parse(JSON.stringify(pixel)))
      // $('#addPixelModal').modal('show')
      this.$bvModal.show('addPixelModal')
    }
  }
})

</script>
<style lang="less" scoped>
.pixel_table {
  tbody {
    display: block;
    overflow-y: auto;
    height: calc(100vh - 18rem);
    table-layout: fixed;
  }

  thead, thead tr, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

.pixel-script {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
